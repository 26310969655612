<template>
  <div>
    <profile-component
      v-if="user"
      :user="user"
      :user-name="user.name"
      :is-show-success-message="isShowSuccessMessage"
      :addresses="addresses"
      :default-address="defaultAddress"
      @updateUserName="onUpdateUserName"
      @createEditAddress="handleCreateEditAddress"
      @openCreateEditDialog="openCreateEditDialog"
      @deleteAddress="handleDeleteAddress"
      @updateDefaultAddress="handleUpdateDefaultAddress"
    />
    <create-edit-address-dialog
      v-if="isShowCreateEditAddressPopup"
      :edited-address="editedAddress"
      :address-suggestions="addressSuggestions"
      :city-suggestions="citySuggestions"
      :address-validation-errors="addressValidationErrors"
      :is-form-submitted="isAddressFormSubmitted"
      @close="handleCloseDialog"
      @createEditAddress="handleCreateEditAddress"
      @loadAddresses="loadAddresses"
      @loadCities="loadCities"
      @fieldChanged="resetError"
    />
  </div>
</template>

<script>
  import ProfileComponent from './components/profile.component';
  import ProfileService from './api/profile.service';
  import { amsClient } from '../../../../service/ams';

  import SharedAmsDataService from '@/shared/services/ams/ams-data.service';
  import CreateEditAddressDialog from '@/entities/account/features/profile/dialogs/create-edit-address.dialog.vue';
  import GeoapifyApi from '@/shared/api/geoapify.api';
  import RocketChatClient from '@/core/service/rocket-chat.service';
  const AmsService = new SharedAmsDataService();
  import Auth from '@/service/authService';
  import CheckoutApi from '@/shared/features/checkout/api';

  const rc = new RocketChatClient();
  export default {
    name: 'ProfileContainer',
    components: {
      CreateEditAddressDialog,
      ProfileComponent,
    },
    data() {
      return {
        customerId: null,
        user: null,
        isShowSuccessMessage: false,
        customerAccessToken: '',
        addresses: [],
        defaultAddress: null,

        isShowCreateEditAddressPopup: false,
        editedAddress: null,
        addressSuggestions: [],
        citySuggestions: [],
        addressValidationErrors: {},
        isAddressFormSubmitted: false,
      }
    },
    async mounted() {
      this.$bus.$emit('updateTitle', 'Profile');
      await amsClient.commandsHandler.customSync({ force: true });
      this.user = ProfileService.getUser();

      const user = Auth.get('user');
      const token = await CheckoutApi.getMultiPassToken(user.privateId, user.first_name, user.last_name);
      this.customerAccessToken = await AmsService.getAccessTokenForAuthUser(token);

      const response  = await ProfileService.getUserAddress(this.customerAccessToken);
      this.customerId = response.customer.id;
      this.defaultAddress = response.customer.defaultAddress;
      this.addresses = response.customer.addresses.edges.map(item => item.node);
      this.setDefaultAddressFirst();
    },
    methods: {
      openCreateEditDialog(address) {
        this.isShowCreateEditAddressPopup = true;
        this.editedAddress = address;
        this.$bus.$emit('toggleFooter');
      },
      handleCloseDialog() {
        this.isShowCreateEditAddressPopup = false;
        this.editedAddress = null;
        this.$bus.$emit('toggleFooter');
      },
      async loadAddresses(form) {
        if (!form.address1) {
          return;
        }

        try {
          const { results } = await GeoapifyApi.searchAddress({
            text: form.address1,
            countryCode: form.countryCode,
          });

          this.addressSuggestions = results;
        } catch (err) {
          console.log('err', err);
          // TODO: show error
        }
      },
      async loadCities(form) {
        if (!form.city) {
          return;
        }

        try {
          const { results } = await GeoapifyApi.searchByCity(form.city, form.countryCode);

          this.citySuggestions = [...new Set(results.map((a) => a.city))];
        } catch (err) {
          console.log('err', err);
          // TODO: show error
        }
      },

      async onUpdateUserName(name) {
        await ProfileService.updateUserName(name);
        this.showSuccessMessage();
      },

      showSuccessMessage() {
        this.isShowSuccessMessage = true;

        setTimeout(() => {
          this.isShowSuccessMessage = false;
        }, 5000);
      },

      resetError({ field }) {
        const { [field]: k, ...errors } = this.addressValidationErrors;
        this.addressValidationErrors = errors;
      },
      async validateAddress(form) {
        const errors = {};

        const { results } = await GeoapifyApi.searchByCity(form.city, form.countryCode);
        if (results.length < 1) {
          errors.city = 'City does not belong to provided country.';
        }
        const { results: zips } = await GeoapifyApi.searchByPostCode(form.zip, form.countryCode);

        if (zips.length < 1) {
          errors.zip = 'Zip code is invalid.';
        }

        return errors;
      },

      async handleCreateEditAddress(form) {
        this.isAddressFormSubmitted = true;
        const errors = await this.validateAddress(form.address);
        if (Object.keys(errors).length > 0) {
          this.addressValidationErrors = errors;
          this.isAddressFormSubmitted = false;
          return;
        }

        const { countryCode, ...address } = form.address;

        const addresses = [...this.addresses];

        if (form.type === 'edit') {
          const editedAddress = addresses.find(item => item.id === address.id);

          for (let item in address) {
            editedAddress[item] = address[item];
          }
        } else {
          addresses.push(address);
        }

        await this.onUpdateUserAddress(addresses);
      },

      async handleDeleteAddress(id) {
        await this.onUpdateUserAddress(this.addresses.filter((a) => a.id !== id));
      },

      async handleUpdateDefaultAddress(addressId) {
        await ProfileService.updateDefaultAddress(addressId, this.customerId);

        const response = await ProfileService.getUserAddress(this.customerAccessToken);
        this.defaultAddress = response.customer.defaultAddress;
        this.addresses = response.customer.addresses.edges.map(item => item.node);
        this.setDefaultAddressFirst();
      },

      async onUpdateUserAddress(addresses) {
        try {
          const response = await ProfileService.updateUserAddress({
            id: this.customerId,
            addresses,
          });

          this.addresses = [...response.customerUpdate.customer.addresses].reverse();
          this.defaultAddress = response.customerUpdate.customer.defaultAddress;
          this.setDefaultAddressFirst();
          this.handleCloseDialog();
        } catch (err) {
          console.log('err', err);
        } finally {
          this.isAddressFormSubmitted = false;
        }
      },

      setDefaultAddressFirst() {
        const index = this.addresses.findIndex(item => item.id === this.defaultAddress.id);
        const item = this.addresses.splice(index, 1)[0];

        if (index >= 0) {
          this.addresses.unshift(item);
        }
      },
    },
  }
</script>