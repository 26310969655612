<template>
  <div class="create-edit-address-form">
    <div class="create-edit-address-form__title">
      {{ editedAddress ? 'Edit Billing Address' : 'New Billing Address' }}
    </div>

    <div style="position: relative;">
      <div class="create-edit-address-form__group">
        <div class="create-edit-address-form__label">
          First Name
        </div>

        <input
          v-model="address.firstName"
          type="text"
          class="create-edit-address-form__input"
          placeholder="First Name"
          maxlength="100"
        >
      </div>

      <div class="create-edit-address-form__group">
        <div class="create-edit-address-form__label">
          Last Name
        </div>

        <input
          v-model="address.lastName"
          type="text" class="create-edit-address-form__input"
          placeholder="Last Name"
          maxlength="100"
        >
      </div>

      <div class="create-edit-address-form__group">
        <div class="create-edit-address-form__label">
          Country
        </div>

        <custom-select-with-search
          :options="countryList"
          :selected-value="address.country"
          placeholder="Country"
          @handleSelectOption="handleSelectCountry"
        />
      </div>

      <div class="create-edit-address-form__group">
        <div class="create-edit-address-form__label">
          City
        </div>
        <form-auto-complete-component
          id="city"
          v-model="address.city"
          :data="citySuggestions"
          placeholder="City"
          @typing="handleLoadCities"
          @input="onFieldChange('city')"
        />

        <div
          v-if="formErrors.city"
          class="create-edit-address-form__error"
        >
          {{ formErrors.city }}
        </div>
      </div>

      <div class="create-edit-address-form__group">
        <div class="create-edit-address-form__label">
          Address
        </div>

        <form-auto-complete-component
          id="address"
          v-model="address.address1"
          :data="addressSuggestions"
          placeholder="Address"
          serialized-field="address_line1"
          @typing="handleLoadAddresses"
          @select="onSelectAddress"
        >
          <template #default="{ option }">
            <div>
              <span class="sp-size-5 mr-1">
                {{ option.address_line1 }}
              </span>
                <span class="create-edit-address-form__autocomplete__address2">
                {{ option.address_line2 }}
              </span>
            </div>
          </template>
        </form-auto-complete-component>
      </div>

      <div class="create-edit-address-form__group">
        <div class="create-edit-address-form__label">
          Zip
        </div>

        <input
          v-model="address.zip"
          type="text"
          class="create-edit-address-form__input"
          placeholder="Zip"
          maxlength="100"
          @input="onFieldChange('zip')"
        >

        <div
          v-if="formErrors.zip"
          class="create-edit-address-form__error"
        >
          {{ formErrors.zip }}
        </div>
      </div>

      <loader
        v-if="isFormSubmitted"
        color="#343434"
        position="absolute"
        background="rgba(255, 255, 255, 0.5)"
        is-full-height
      />

      <button
        :disabled="!isValidForm"
        class="create-edit-address-form__button"
        :class="{'create-edit-address-form__button--disabled': !isValidForm}"
        @click="handleCreateEditAddress"
      >
        {{ editedAddress ? 'Edit Address' : 'Add Address' }}
      </button>
    </div>
  </div>
</template>

<script>
  import FormAutoCompleteComponent from '@/shared/components/form-components/form-autocomplete.component.vue';
  import CustomSelectWithSearch from '@/shared/components/form-components/custom-select-with-search.vue';
  import Loader from '@/components/loader/loader.vue';

  export default {
    name: 'CreateEditAddressFormComponent',
    components: {
      Loader,
      FormAutoCompleteComponent,
      CustomSelectWithSearch,
    },
    props: {
      isFormSubmitted: {
        type: Boolean,
        default: false,
      },
      formErrors: {
        type: Object,
        default: () => ({}),
      },
      addressSuggestions: {
        type: Array,
        default: () => [],
      },
      citySuggestions: {
        type: Array,
        default: () => [],
      },
      editedAddress: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        countryList: [
          { label: 'United States', value: 'United States', code: 'us' },
          { label: 'Poland', value: 'Poland', code: 'pl' },
          { label: 'Ukraine', value: 'Ukraine', code: 'ua' },
        ],
        address: {
          firstName: '',
          lastName: '',
          country: '',
          city: '',
          zip: '',
          address1: '',
          countryCode: '',
        },
      }
    },
    computed: {
      isValidForm() {
        const { firstName, lastName, country, city, zip, address1 } = this.address;

        return firstName && lastName && country && city && zip && address1;
      },
    },

    watch: {
      'address.country': {
        handler(val) {
          this.address.countryCode = this.countryList.find((c) => c.label === val)?.code;
        },
      },
    },
    mounted() {
      if (this.editedAddress) {
        this.address.firstName = this.editedAddress.firstName;
        this.address.lastName = this.editedAddress.lastName;
        this.address.country = this.editedAddress.country;
        this.address.city = this.editedAddress.city;
        this.address.zip = this.editedAddress.zip;
        this.address.address1 = this.editedAddress.address1;
        this.address.id = this.editedAddress.id;
      }
    },
    methods: {
      onFieldChange(field, value) {
        this.$emit('fieldChanged', { field });
      },
      handleCreateEditAddress() {
        this.$emit('createEditAddress', {
          type: this.address.id ? 'edit' : 'create',
          address: this.address,
        });
      },
      handleSelectCountry(country) {
        this.address.country = country;
      },
      handleLoadAddresses() {
        this.$emit('loadAddresses', this.address);
      },
      handleLoadCities() {
        this.$emit('loadCities', this.address);
      },
      onSelectAddress(address) {
        this.address = {
          ...this.address,
          zip: address.postcode,
          city: address.city,
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .create-edit-address-form {
    color: #343434;
    position: relative;

    &__autocomplete {
      &__address1 {

      }

      &__address2 {
        font-size: 0.75rem;
        color: #8a8183;
      }
    }

    &__title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }

    &__group {
      margin-bottom: 15px;
    }

    &__label {
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
    }

    &__input, &__select {
      padding: 12px 20px;
      width: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      border: 1px solid #edf1f4;
      border-radius: 7px;
      outline: none;
    }

    &__error {
      margin-bottom: 20px;
      font-size: 14px;
      color: red;
    }

    .first-option {
      display: none;
    }

    &__input::placeholder, &__select:invalid {
      color: #757575;
    }

    &__select option {
      color: #343434;
    }

    &__button {
      margin-top: 5px;
      padding: 18px 20px;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      text-align: center;
      background: #21a66e;
      border-radius: 5px;
      outline: none;

      &--disabled {
        background: #ccc;
      }
    }
  }
</style>