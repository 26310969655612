export default `
  mutation customerUpdate($input: CustomerInput!) {
    customerUpdate(input: $input) {
      userErrors {
        field
        message
      }
      customer {
        id
        defaultAddress {
          id
          address1
          city
          country
          lastName
          firstName
          zip
        }
        addresses {
          id
          zip
          address1
          city
          country
          lastName
          firstName
        }
      }
    }
  }
`;
