<template>
  <h2 class="account-title">
    <span class="sp-is-desktop-lg account-title--text">{{ title }}</span>

    <router-link to="/account" class="sp-is-mobile-lg account-title--text">
      <img
        :src="require('@/assets/icons/account/left-arrow-black.svg')"
        alt="return"
      />

      {{ title }}
    </router-link>
  </h2>
</template>

<script>
  export default {
    name: 'AccountPageTitle',
    props: {
      title: {
        type: String,
        default: '',
        require: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .account-title {
    margin-bottom: 0;

    &--text {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #343434;
    }

    img {
      margin-right: 6px;
    }

    .sp-is-desktop-lg {
      display: none;

      @media screen and (min-width: 1001px) {
        display: block;
      }
    }

    .sp-is-mobile-lg {
      display: block;

      @media screen and (min-width: 1001px) {
        display: none;
      }
    }
  }
</style>
