import Auth from '../../../../../service/authService';
import { amsClient } from '@/service/ams';
import getAddressQuery from './queries/get-address.query';
import getAddressCreateMutation from './mutations/create-address.mutation';
import updateDefaultAddressMutation from './mutations/update-default-address.mutation';

import makeRequest from '../../../../../core/http/storefront.client';
import makeRequestE from '../../../../../core/http/e-commerce.client';

export default {
  getUser() {
    return Auth.get('user');
  },

  async updateUserName(name) {
    await amsClient.account.update({ name });
  },

  async getUserAddress(token) {
    return await makeRequest(getAddressQuery, {
      customerAccessToken: token,
    });
  },

  async updateUserAddress(data) {
    return await makeRequestE(getAddressCreateMutation, {
      input: data,
    });
  },

  async updateDefaultAddress(addressId, customerId) {
    return await makeRequestE(updateDefaultAddressMutation, {
      addressId,
      customerId,
    });
  },
};
