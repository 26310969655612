<template>
  <profile-container/>
</template>

<script>
  import ProfileContainer from './profile.container';

  export default {
    name: 'ProfileView',
    components: {
      ProfileContainer,
    },
  }
</script>