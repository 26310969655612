<template>
  <div class="delete-address-popup" @click.self="closeDialog">
    <div class="delete-address-popup__popup">
      <div class="delete-address-popup__title">
        Are you sure you want to delete the Billing Address?
      </div>

      <div class="delete-address-popup__buttons">
        <button class="delete-address-popup__button" @click="closeDialog">No</button>
        <button class="delete-address-popup__button delete-address-popup__button--success" @click="deleteAddress">Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DeleteAddressRecheckDialog',
    methods: {
      deleteAddress() {
        this.$emit('deleteAddress');
      },
      closeDialog() {
        this.$emit('closeDeleteAddressPopup');
      },
    },
  }
</script>

<style scoped lang="scss">
  .delete-address-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 10000;

    &__popup {
      padding: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 400px;
      border-radius: 10px;
      background: #fff;
      transform: translate(-50%, -50%);
    }

    &__title {
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }

    &__buttons {
      display: flex;
      gap: 20px;
    }

    &__button {
      padding: 15px;
      width: calc(50% - 10px);
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      background: #ccc;
      border-radius: 5px;
      outline: none;

      &--success {
        background: #21a66e;;
      }
    }

    @media(max-width: 767px) {
      &__popup {
        width: calc(100% - 30px);
        max-width: none;
        left: 15px;
        transform: translateY(-50%);
      }
    }
  }
</style>