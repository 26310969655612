<template>
  <div class="profile">
    <account-page-title title="Profile" class="profile__title" />

    <!-- <div class="profile__title">
      Profile
    </div>

    <router-link to="/account" class="profile__title profile__title--mobile">
      <img :src="require('@/assets/icons/account/left-arrow-black.svg')" alt="" class="profile__title-arrow">

      Profile
    </router-link> -->

    <div class="profile__user">
      <div class="profile__user-image-container">
        <img
          v-if="user.chat_user.avatar_url"
          :src="user.chat_user.avatar_url"
          class="profile__user-image"
          alt=""
        />
        <div v-else class="profile__user-first-letter">
          {{ user.first_name[0] }}
        </div>
      </div>

      <div class="profile__user-info">
        <div class="profile__user-full-name">
          {{ user.first_name }} {{ user.last_name }}
        </div>
        <div class="profile__user-email">
          {{ user.username }}
        </div>
      </div>
    </div>

    <div class="profile__main-info profile__grid">
      <div class="profile__column">
        <div class="profile__info-item info-item">
          <form-input-component
            v-model="user.first_name"
            label="First Name"
            disabled
          />
        </div>
      </div>
      <div class="profile__column">
        <form-input-component
          v-model="user.last_name"
          label="Last Name"
          disabled
        />
      </div>
      <div class="profile__column">
        <form-input-component
          v-model="user.username"
          :title="user.username"
          label="Email"
          disabled
        />
      </div>
      <div class="profile__column">
        <div class="info-item__title">Chat Name</div>
        <div class="info-item__form">
          <input
            ref="userName"
            :value="userName"
            type="text"
            maxlength="25"
            class="info-item__input"
            placeholder="Enter Chat Name"
          />
          <button class="info-item__submit" @click="updateUserName">
            Save
          </button>
          <div
            class="info-item__success-message"
            :class="{
              'info-item__success-message--show': isShowSuccessMessage,
            }"
          >
            Chat Name was successfully updated
          </div>
        </div>
      </div>
    </div>

    <div class="profile__address">
      <div class="profile__address-title">Billing Addresses</div>

      <div class="profile__grid">
        <div class="profile__column profile__column--hide-mobile">
          <button
            class="profile__add-address"
            @click="() => handleCreateEditDialog()"
          >
            Add new Address
          </button>
        </div>

        <div v-for="address of addresses" class="profile__column">
          <div class="profile__address-item address">
            <div class="address__content">
              <div class="address__title">Address</div>

              <div class="address__user-name">
                {{ address.firstName }} {{ address.lastName }}
              </div>

              <div class="address__user-address">
                {{ address.address1 }}, {{ address.city }}, {{ address.zip }},
                {{ address.country }}
              </div>

              <button
                v-if="address.id !== defaultAddress.id"
                class="address__set-default"
                @click="handleUpdateDefaultAddress(address.id)"
              >
                Set as default
              </button>
            </div>

            <div class="address__buttons">
              <button
                class="address__button address__button--edit"
                @click="handleCreateEditDialog(address)"
              >
                Edit
              </button>
              <button
                class="address__button"
                @click="handleDeleteAddressPopup(address.id)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div class="profile__column profile__column--show-mobile">
          <button
            class="profile__add-address"
            @click="() => handleCreateEditDialog()"
          >
            Add new Address
          </button>
        </div>
      </div>

      <slot name="createEditAddressDialog" />

      <delete-address-recheck-dialog
        v-if="isShowDeleteAddressPopup"
        @deleteAddress="handleDeleteAddress"
        @closeDeleteAddressPopup="closeDeleteAddressPopup"
      />
    </div>
  </div>
</template>

<script>
  import AccountPageTitle from '../../../components/account-page-title.component';
  import DeleteAddressRecheckDialog from '../dialogs/delete-address-recheck.dialog';
  import FormInputComponent from '@/shared/components/form-components/form-input.component.vue';

  export default {
    name: 'ProfileComponent',
    components: {
      AccountPageTitle,
      FormInputComponent,
      DeleteAddressRecheckDialog,
    },
    props: {
      user: {
        type: Object,
        default: () => ({}),
      },
      userName: {
        type: String,
        default: '',
      },
      isShowSuccessMessage: {
        type: Boolean,
        default: false,
      },
      addresses: {
        type: Array,
        default: () => [],
      },
      defaultAddress: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        isShowDeleteAddressPopup: false,
        isShowCreateEditAddressPopup: false,
        editedAddress: null,
        deletedId: null,
      };
    },
    methods: {
      handleCreateEditDialog(address) {
        this.$emit('openCreateEditDialog', address);
      },
      updateUserName() {
        this.$emit('updateUserName', this.$refs.userName.value);
      },
      handleUpdateDefaultAddress(addressId) {
        this.$emit('updateDefaultAddress', addressId);
      },
      handleDeleteAddressPopup(id) {
        this.isShowDeleteAddressPopup = true;
        this.deletedId = id;
      },
      closeDeleteAddressPopup() {
        this.isShowDeleteAddressPopup = false;
        this.deletedId = null;
      },
      handleDeleteAddress() {
        this.$emit('deleteAddress', this.deletedId);
        this.closeDeleteAddressPopup();
      },
    },
  };
</script>

<style scoped lang="scss">
  .profile {
    margin-bottom: 60px;
    color: #343434;

    &__title {
      margin-bottom: 20px;
    }

    &__user {
      margin-bottom: 30px;
      padding: 25px;
      display: flex;
      align-items: center;
      gap: 15px;
      border-radius: 10px;
      background: #f8f8fb;
    }

    &__user-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background: coral;
      border-radius: 50px;
      overflow: hidden;
    }

    &__user-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__user-first-letter {
      font-size: 35px;
      color: #fff;
      text-transform: uppercase;
    }

    &__user-info {
      width: calc(100% - 75px);
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }

    &__user-email {
      font-weight: 400;
    }

    &__main-info {
      margin-bottom: 50px;
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 30px;
    }

    &__column {
      width: calc((100% / 3) - (40px / 3));

      &--show-mobile {
        display: none;
      }
    }

    &__address-title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }

    &__add-address {
      padding: 65px 50px;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      border: 1px dashed #e8e8f7;
      border-radius: 10px;
      outline: none;
    }

    @media (max-width: 1128px) {
      &__column {
        width: 50%;
        flex: 0 0 calc(50% - 10px);
      }
    }

    @media (max-width: 1000px) {
      &__column {
        width: 50%;
        flex: 0 0 calc(50% - 10px);
      }

      &__title-arrow {
        margin-right: 8px;
      }
    }

    @media (max-width: 767px) {
      &__user {
        padding: 10px;
      }

      &__grid {
        display: block;
      }

      &__column {
        width: 100%;

        &--hide-mobile {
          display: none;
        }

        &--show-mobile {
          display: block;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      &__add-address {
        padding: 15px;
        font-weight: 500;
        line-height: 18px;
        border: 1px solid #eaecf3;
      }
    }
  }

  .info-item {
    &__title {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
    }

    &__value {
      padding: 15px 20px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      background: #f8f8fb;
      border-radius: 7px;
    }

    &__form {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    &__input {
      padding: 9px 16px;
      width: calc(100% - 100px);
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      border: 1px solid #edf1f4;
      border-radius: 7px;
      outline: none;
    }

    &__submit {
      padding: 15px;
      width: 90px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      background: #21a66e;
      border-radius: 5px;
      outline: none;
    }

    &__success-message {
      display: none;
      font-size: 14px;
      color: #21a66e;

      &--show {
        display: block;
      }
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    border: 1px solid #edf1f4;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;

    &__content {
      margin-bottom: auto;
      padding: 15px 20px;
    }

    &__title {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
    }

    &__user-name,
    &__user-address {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }

    &__user-address {
      margin-bottom: 5px;
    }

    &__set-default {
      padding: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #2f76e1;
      outline: none;
    }

    &__buttons {
      display: flex;
      border-top: 1px solid #edf1f4;
    }

    &__button {
      padding: 15px;
      width: 50%;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #2f76e1;
      outline: none;

      &--edit {
        border-right: 1px solid #edf1f4;
      }
    }

    @media (max-width: 767px) {
      background: #f8f8fb;
    }
  }
</style>
