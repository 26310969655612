<template>
  <div class="create-edit-address" @click.self="handleCloseDialog">
    <div class="create-edit-address__popup">
      <div class="create-edit-address__close" @click="handleCloseDialog">
        <img
          :src="require('@/assets/icons/close-button.svg')"
          alt="Close button"
          class="create-edit-address__close-icon"
        >
      </div>

      <create-edit-address-form-component
        class="create-edit-address__form"
        :edited-address="editedAddress"
        :address-suggestions="addressSuggestions"
        :city-suggestions="citySuggestions"
        :form-errors="addressValidationErrors"
        :is-form-submitted="isFormSubmitted"
        @createEditAddress="handleCreateEditAddress"
        @loadAddresses="handleLoadAddresses"
        @loadCities="handleLoadCities"
        @fieldChanged="handleChangedField"
      />
    </div>
  </div>
</template>

<script>
  import CreateEditAddressFormComponent from '../components/create-edit-address-form.component';

  export default {
    name: 'CreateEditAddressDialog',
    components: {
      CreateEditAddressFormComponent,
    },
    props: {
      isFormSubmitted: {
        type: Boolean,
        default: false,
      },
      addressSuggestions: {
        type: Array,
        default: () => [],
      },
      citySuggestions: {
        type: Array,
        default: () => [],
      },
      addressValidationErrors: {
        type: Object,
        default: () => ({}),
      },
      editedAddress: {
        type: Object,
        default: null,
      },
    },
    methods: {
      handleCloseDialog() {
        this.$emit('close');
      },

      handleCreateEditAddress(data) {
        this.$emit('createEditAddress', data);
      },

      handleLoadAddresses(form) {
        this.$emit('loadAddresses', form);
      },

      handleLoadCities(form) {
        this.$emit('loadCities', form);
      },

      handleChangedField(...args) {
        this.$emit('fieldChanged', ...args);
      },
    },
  }
</script>

<style scoped lang="scss">
  .create-edit-address {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 10000;

    &__popup {
      padding: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 460px;
      border-radius: 10px;
      background: #fff;
      transform: translate(-50%, -50%);
    }

    &__close {
      display: flex;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }

    @media(max-width: 767px) {
      &__popup {
        overflow:scroll;
        height: 70%;
        width: calc(100% - 30px);
        max-width: none;
        left: 15px;
        transform: translateY(-50%);
      }
    }
  }
</style>