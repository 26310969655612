export default `
  mutation customerUpdateDefaultAddress($addressId: ID!, $customerId: ID!) {
    customerUpdateDefaultAddress(addressId: $addressId, customerId: $customerId) {
      customer {
        defaultAddress {
          id
        }
      }
      userErrors {
        message
      }
    }
  }
`;
