export default `
  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      defaultAddress {
        id
        address1
        city
        country
        lastName
        firstName
        zip
      }
      addresses(first: 20, reverse: true) {
        edges {
          node {
            id
            address1
            city
            country
            lastName
            firstName
            zip
          }
        }
      }
    }
  }
`;
